import {
  Layout,
  Table,
  Form,
  Col,
  Row,
  Button,
  Space,
  Checkbox,
  Modal,
  List,
  Skeleton,
} from "antd";
import { useGetProjectAccessQuery } from "../features/api/apiSlice";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import ProjectTabs from "./ProjectTabs";
// import { useDeleteDrawingMutation } from '../features/api/apiSlice';
import { ExclamationCircleFilled } from "@ant-design/icons";

import AccessEdit from "./AccessEdit";
import useIsMobile from "../utils/mediaQuery";

const { Header, Content } = Layout;

const { confirm } = Modal;

export default function Access() {
  const { projectId } = useParams();

  const [openAccessEdit, setOpenAccessEdit] = useState(false);

  const [formAccessEdit] = Form.useForm();

  const isMobile = useIsMobile();

  const {
    data: project,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetProjectAccessQuery(projectId);

  const handleAccessEdit = (access) => {
    formAccessEdit.setFieldsValue(access);
    setOpenAccessEdit(true);
  };

  const showRevokeConfirm = (drawingId) => {
    confirm({
      title: "Are you sure delete drawing?",
      icon: <ExclamationCircleFilled />,
      content: "You cannot undo this action",
      okText: "Confirm",
      okType: "danger",
      cancelText: "No",
      onOk: async (drawingId) => {
        try {
          // await deleteDrawing({ drawingId, projectId }).unwrap();
        } catch (e) {
          console.error(e);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <Layout>
      {!isMobile && (
        <Table
          style={{ width: "100%" }}
          loading={isLoading}
          columns={[
            {
              title: "Email",
              dataIndex: "email",
              key: "email",
            },
            {
              title: "Phone",
              dataIndex: "phone",
              key: "phone",
            },
            {
              title: "First Name",
              dataIndex: "first_name",
              key: "first_name",
            },
            {
              title: "Last Name",
              dataIndex: "last_name",
              key: "last_name",
            },
            {
              title: "Role",
              dataIndex: "role",
              key: "role",
              render: (role) => {
                switch (role) {
                  case "ProjectOwner":
                    return "Owner";
                  case "ProjectManager":
                    return "Project Manager";
                  case "SiteEngineer":
                    return "Site Engineer";
                  default:
                    return "UNKNOWN";
                }
              },
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
            },
            {
              title: "Action",
              key: "action",
              render: (_, record) => {
                switch (record.role) {
                  case "ProjectOwner":
                    return;
                  default:
                    return (
                      <Space size="middle">
                        <a onClick={() => handleAccessEdit(record)}>Edit</a>
                        <Button
                          onClick={() => showRevokeConfirm(record.id)}
                          danger
                        >
                          Revoke
                        </Button>
                      </Space>
                    );
                }
              },
            },
          ]}
          dataSource={project?.access}
        />
      )}
      {isMobile && (
        <List
          style={{ margin: "75px 20px" }}
          itemLayout="vertical"
          size="large"
          loading={isLoading}
          pagination={{ position: "bottom", align: "center" }}
          dataSource={project?.access || []}
          renderItem={(item) => (
            <List.Item
              key={item.email}
              actions={[
                item.role !== "ProjectOwner" && (
                  <Button
                    type="link"
                    onClick={() => handleAccessEdit(item)}
                    key="edit"
                  >
                    Edit
                  </Button>
                ),
                item.role !== "ProjectOwner" && (
                  <Button
                    danger
                    type="link"
                    onClick={() => showRevokeConfirm(item.id)}
                    key="revoke"
                  >
                    Revoke
                  </Button>
                ),
              ]}
            >
              <Skeleton loading={isLoading} active>
                <List.Item.Meta
                  title={
                    <>
                      <b>
                        {item.first_name} {item.last_name}
                      </b>
                      <br />
                      <small>{item.email}</small>
                    </>
                  }
                  description={
                    <>
                      <p>Phone: {item.phone || "N/A"}</p>
                      <p>
                        Role:{" "}
                        {(() => {
                          switch (item.role) {
                            case "ProjectOwner":
                              return "Owner";
                            case "ProjectManager":
                              return "Project Manager";
                            case "SiteEngineer":
                              return "Site Engineer";
                            default:
                              return "UNKNOWN";
                          }
                        })()}
                      </p>
                      <p>Status: {item.status || "N/A"}</p>
                    </>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      )}
      <AccessEdit
        projectId={projectId}
        form={formAccessEdit}
        open={openAccessEdit}
        setOpen={setOpenAccessEdit}
      />
    </Layout>
  );
}
