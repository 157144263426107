import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Input, DatePicker, message, Image } from "antd";

import {useGetSurveysQuery, usePostAssetMutation} from "../features/api/apiSlice";
import useIsMobile from "../utils/mediaQuery";
import { Map, Marker, MapStyle, config } from '@maptiler/sdk';
import {current} from "@reduxjs/toolkit";

//TODO: Should be child of Assets
export default function AddAssetForm({ open, setOpen, projectId, currentAsset, updateCurrentAsset}) {
  const [form] = Form.useForm();
  const [postAsset, { isLoading }] = usePostAssetMutation();
  const [photos, setPhotos] = useState([]); // Photos in Base64
  const isMobile = useIsMobile();
  const [refreshKey, setRefreshKey] = useState(0);
  const forceRefresh = () => {
    setRefreshKey((prev) => prev + 1); // Increment the counter to trigger re-render
  };


  useEffect(() => {
    window.refreshAssetAddKey = forceRefresh

    // Cleanup to avoid leaking the global
    return () => {
      delete window.refreshAssetAddKey;
    };
  }, []);

  // Function to open native camera from web
  const openReactNativeCamera = () => {
    console.log('post triggerCamera message');

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: 'triggerCamera' })
      );
    }
  }

  // Function to open gallery
  const openPhotoGallery = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('triggerGallery');
    } else {
      message.error("WebView is not available for gallery access");
    }
  }

  useEffect(() => {
    // Defines the event listener
    const handleCapturedPhoto = (event) => {
      const photoUri = event.data;
      console.log('Captured Photo URI:', photoUri);
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(photoUri);
      }
      const photoElement = document.getElementById('photo');
      if (photoElement) {
        photoElement.src = photoUri;
      }
    };

    // Adds the listener
    window.addEventListener('message', handleCapturedPhoto);

    // Cleans up the listener on unmount
    return () => {
      window.removeEventListener('message', handleCapturedPhoto);
    };
  }, []);

  useEffect(() => {
    // Load photos from local storage on component mount
    const storedPhotos = localStorage.getItem("photos");
    if (storedPhotos) {
      setPhotos(JSON.parse(storedPhotos));
    }

    // Listen for messages from the WebView
    const handleWebViewMessage = (event) => {
      try {
        const message = JSON.parse(event.data);

        switch (message.type) {
          case 'CAMERA_PHOTO':
            // Handle new photo from native camera
            const newPhoto = message.photo.base64;
            const updatedPhotos = [...photos, newPhoto];

            // Update local state and storage
            setPhotos(updatedPhotos);
            localStorage.setItem("photos", JSON.stringify(updatedPhotos));

            // Update form files
            form.setFieldsValue({
              files: [{
                uid: `-${updatedPhotos.length}`,
                name: message.photo.fileName,
                status: 'done',
                url: newPhoto,
                originFileObj: base64ToFile(newPhoto, message.photo.fileName, message.photo.fileType)
              }]
            });
            break;

          case 'GALLERY_PHOTO':
            // Handle new photo from gallery
            const newGalleryPhoto = message.photo.base64;
            const newPhotos = [...photos, newGalleryPhoto];

            // Update local state and storage
            setPhotos(newPhotos);
            localStorage.setItem("photos", JSON.stringify(newPhotos));

            // Update form files
            form.setFieldsValue({
              files: [{
                uid: `-${newPhotos.length}`,
                name: message.photo.fileName,
                status: 'done',
                url: newGalleryPhoto,
                originFileObj: base64ToFile(newGalleryPhoto, message.photo.fileName, message.photo.fileType)
              }]
            });
            break;

          case 'photos-data':
            // Handle retrieving stored photos
            const receivedPhotos = message.data || [];
            const combinedPhotos = [...new Set([...photos, ...receivedPhotos])];
            setPhotos(combinedPhotos);
            localStorage.setItem("photos", JSON.stringify(combinedPhotos));
            break;
        }
      } catch (error) {
        console.error("Error processing WebView message:", error);
      }
    };

    window.addEventListener("message", handleWebViewMessage);

    // Trigger fetching photos from React Native when the modal opens
    // if (open) {
    //   if (window.ReactNativeWebView) {
    //     window.ReactNativeWebView.postMessage(
    //       JSON.stringify({ type: 'RETRIEVE_PHOTOS' })
    //     );
    //   }
    // }

    return () => {
      window.removeEventListener("message", handleWebViewMessage);
    };
  }, [open]);

  // Utility function to convert base64 to File
  const base64ToFile = (base64, filename, type) => {
    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };


  const handleConfirm = async () => {
    try {

      const values = await form.validateFields();
      const { name, installed_at, next_maintenance, files } = values;

      console.log("Adding Asset");

      const recorded_by = localStorage.getItem("email");

      // const convertFileToBase64 = (file) =>
      //   new Promise((resolve, reject) => {
      //     const reader = new FileReader();
      //     reader.onload = () => resolve(reader.result.split(",")[1]); // Get Base64 content
      //     reader.onerror = reject;
      //     reader.readAsDataURL(file.originFileObj);
      //   });

      // let assetPhotos = [];
      // if (files && files.length > 0) {
      //   // Convert all files to Base64
      //   assetPhotos = await Promise.all(
      //     files.map(async (file) => ({
      //       photo_data: await convertFileToBase64(file),
      //       mime_type: file.type || "application/zip",
      //     }))
      //   );
      // }

      const asset = {
        note: name,
        uuid: currentAsset?.uuid?.file,
        geolocation: currentAsset.lngLat,
        installed_at: installed_at ? installed_at.format("YYYY-MM-DD") : null,
        next_maintenance: next_maintenance
          ? next_maintenance.format("YYYY-MM-DD")
          : null,
        recorded_by,
        // photos: assetPhotos,
      };

      console.log(asset);

      await postAsset({ projectId, asset }).unwrap();
      message.success("Asset added successfully!");

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage("refresh-assets");
      }

      // assets = useGetSurveysQuery()
      currentAsset.refetchAll();

      form.resetFields();
      setOpen(false);
    } catch (error) {
      console.error("Error adding asset:", error);
      message.error("Failed to add asset.");
    }
  };


  const handleModalClose = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("hide-camera-button");
    }
    form.resetFields();
    setOpen(false);
  };

  const normFile = (e) => (Array.isArray(e) ? e : e?.fileList);

  useEffect(() => {
    // This will run whenever `someState` changes
    console.log("CurrentAsset changed:");
  }, [currentAsset]); // Add `someState` as a dependency

  return (
    <Modal
      refreshKey={refreshKey}
      open={open}
      title="Add Asset"
      okText="Confirm"
      onCancel={handleModalClose}
      onOk={handleConfirm}
      okButtonProps={{ loading: isLoading }}
      style={{
        marginBottom: isMobile ? "350px" : "0px"
      }}
    >


      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Asset Name"
          rules={[{ required: true, message: "Please enter the asset name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="installed_at"
          label="Installed At"
          rules={[{ required: true, message: "Please select installation date" }]}
        >
          <DatePicker
              // style={{
              //   position: "relative",
              //   zIndex: 1000000,
              //
              // }}
              popperPlacement='bottom'
              popperModifiers={{
                flip: {
                  behavior: ['bottom'] // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
                }
              }}
              disabledKeyboardNavigation
              onFocus={e => e.target.blur()}
          />
        </Form.Item>

        <Form.Item
          name="next_maintenance"
          label="Next Maintenance"
          rules={[{ required: false }]}
        >
          <DatePicker
              disabledKeyboardNavigation
              onFocus={e => e.target.blur()}
          />
        </Form.Item>

        {/*{!isMobile && (*/}
        {/*  <Form.Item label="Upload Files">*/}
        {/*    <Form.Item*/}
        {/*      name="files"*/}
        {/*      valuePropName="fileList"*/}
        {/*      getValueFromEvent={normFile}*/}
        {/*      noStyle*/}
        {/*    >*/}
        {/*      <Upload.Dragger*/}
        {/*        maxCount={1}*/}
        {/*        beforeUpload={() => false} // Prevent default upload*/}
        {/*        accept="application/zip,image/*"*/}
        {/*      >*/}
        {/*        <p className="ant-upload-drag-icon">*/}
        {/*          <InboxOutlined />*/}
        {/*        </p>*/}
        {/*        <p className="ant-upload-text">*/}
        {/*          Click or drag file to this area to upload*/}
        {/*        </p>*/}
        {/*        <p className="ant-upload-hint">Support for image or ZIP files.</p>*/}
        {/*      </Upload.Dragger>*/}
        {/*    </Form.Item>*/}
        {/*  </Form.Item>*/}
        {/*)}*/}

        {isMobile && (
          <Form.Item style={{display: "flex", justifyContent: "center"}}>
            <div>
              {photos.map((photo, index) => (
                <Image
                  key={index}
                  src={`data:image/jpeg;base64,${photo}`}
                  alt={`Photo ${index + 1}`}
                  style={{ width: 100, margin: 5 }}
                />
              ))}
            </div>
            <p style={{textAlign: "center", display: currentAsset?.uuid?.file != null ? "block" : "none"}}>Image Loaded</p>
            <Button
              type="primary"
              onClick={openReactNativeCamera}
              style={{ alignSelf: "center" }}
            >
              { currentAsset?.uuid?.file ? "Re-Take Photo" : "Open Camera" }
            </Button>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}