import { Tabs, Dropdown, Button, Form } from "antd";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaRegMap } from "react-icons/fa6";
import { CgAdd } from "react-icons/cg";
import { FaDrawPolygon, FaMapMarkerAlt } from "react-icons/fa";
import {
  DownOutlined,
  InfoCircleOutlined,
  UsergroupAddOutlined,
  NodeIndexOutlined,
  ExportOutlined,
  PlusCircleOutlined,
  UserAddOutlined,
  CameraFilled,
  CameraOutlined,
  UploadOutlined,
} from "@ant-design/icons";


import ProjectMap from "./ProjectMap";

import Access from "./Access";
import AccessInvite from "./AccessInvite";

import Surveys from "./Surveys";
import Vectors from "./Vectors";

import Drawings from "./Drawings";
import AddDrawingsForm from "./DrawingsAdd";

import Assets from "./Assets";
import AddAssetForm from "./AssetAdd";

import fileDownload from "js-file-download";
import {
  useExportSurveysMutation,
  useExportVectorsMutation,
} from "../features/api/apiSlice";
import useIsMobile from "../utils/mediaQuery";
import {current} from "@reduxjs/toolkit";

const downloadBlob = (content, filename, contentType) => {
  var blob = new Blob([content], { type: contentType });
  var url = URL.createObjectURL(blob);

  var pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
};

export default function ProjectTabs(props) {
  const { projectId, tabKey } = useParams();


  const navigate = useNavigate();
  const [exportSurveys] = useExportSurveysMutation();
  const [exportVectors] = useExportVectorsMutation();

  const [drawingsSelected, setDrawingsSelected] = useState([]);
  const [assetsSelected, setAssetsSelected] = useState([]);
  const [surveysSelected, setSurveysSelected] = useState([]);
  const [vectorsSelected, setVectorsSelected] = useState([]);

  const [openDrawingsAddForm, setOpenDrawingsAddForm] = useState(false);
  const [formDrawingsAdd] = Form.useForm();

  const [openAssetAddForm, setOpenAssetAddForm] = useState(false);
  const [formAssetAdd] = Form.useForm();

  const [openAccessInvite, setOpenAccessInvite] = useState(false);
  const [formAccessInvite] = Form.useForm();

  const isMobile = useIsMobile();
  const [currentAsset, setCurrentAsset] = useState({ uuid: null, lngLat: [0, 0], addLocationResult: false, refetchAll: () => {}});
  const [assets, setAssets] = useState({})

  const updateCurrentAsset = (key, value) => {
      const newCurrentAsset = currentAsset;
      currentAsset[key] = value;
      setCurrentAsset(newCurrentAsset);
  }

  const refreshAssets = () => {

  }

  const exportVectorsSelected = async (e) => {
    const { data } = await exportVectors({
      drawingsIds: vectorsSelected.map((v) => v.drawing_id),
      projectId,
    });
    fileDownload(data, `export-vectors.zip`);
  };

  // Event trigger to open camera on react native
  const openReactNativeCamera = () => {
    console.log('post triggerCamera message');

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: 'triggerCamera' })
      );
    }
  }

  // Event trigger to open gallery on react native
  const openPhotoGallery = () => {
    console.log('post triggerGallery message');

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: 'triggerGallery' })
      );
    }
  }

  const exportSurveysSelected = async (e) => {
    switch (e.key) {
      case "points-shape":
        const { data } = await exportSurveys({
          surveyIds: surveysSelected.map((s) => s.id),
          projectId,
        });
        fileDownload(data, `export.zip`);
        break;
      case "points-csv":
        const csv = [
          [
            "id",
            "longitude",
            "latitude",
            "depth",
            "photo_url",
            "drawing_name",
            "created_at",
          ],
        ]
          .concat(
            surveysSelected.map((s) =>
              [
                s.id,
                s.longitude,
                s.latitude,
                s.depth,
                `https://ucarecdn.com/${s.uuid}/`,
                s.drawing_name,
                s.created_at,
              ]
                .map((k) => `"${k}"`)
                .join(",")
            )
          )
          .join("\n");

        downloadBlob(csv, "export.csv", "text/csv;charset=utf-8;");
        break;
      default:
        break;
    }
  };

  return (
    <Tabs
      style={{ height: "100%" }}
      tabPosition={isMobile ? "bottom" : "top"}
      tabBarStyle={
        !isMobile
          ? {
            backgroundColor: "white",
            padding: "0 16px 0 16px",
          }
          : {
            backgroundColor: "white",
            padding: "0 16px 0 16px",
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            zIndex: 9999,
          }
      }
      centered={isMobile ? true : false}
      activeKey={tabKey}
      tabBarExtraContent={
        (tabKey == "surveys" && !isMobile && (
          <Dropdown
            trigger={["click"]}
            menu={{
              onClick: exportSurveysSelected,
              items: [
                {
                  label: "Points in Shape file format (.shp)",
                  key: "points-shape",
                },
                {
                  label: "Points in CSV",
                  key: "points-csv",
                },
              ],
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Button disabled={0 == surveysSelected?.length}>
                Export
                <DownOutlined />
              </Button>
            </a>
          </Dropdown>
        )) ||
        (tabKey == "vectors" && (
          <Button
            style={
              isMobile
                ? {
                  position: "fixed",
                  bottom: "75px",
                  right: "20px",
                  width: "fit-content",
                  background: "transparent",
                  border: "none",
                  zIndex: 9999,
                }
                : {}
            }
            onClick={exportVectorsSelected}
            disabled={0 == vectorsSelected?.length}
          >
            {isMobile ? (
              <ExportOutlined
                style={{ color: "#30bbd0", fontSize: "xxx-large" }}
              />
            ) : (
              <>Export vectors</>
            )}
          </Button>
        )) ||
        (tabKey == "drawings" && (
          <Button
            style={
              isMobile
                ? {
                  position: "fixed",
                  bottom: "75px",
                  right: "20px",
                  width: "fit-content",
                  background: "transparent",
                  border: "none",
                  zIndex: 9999,
                }
                : {}
            }
            onClick={() => setOpenDrawingsAddForm(true)}
          >
            {isMobile ? (
              <PlusCircleOutlined
                style={{ color: "#30bbd0", fontSize: "xxx-large" }}
              />
            ) : (
              <>Add layers</>
            )}
          </Button>
        )) ||
        (tabKey == "assets" && (
          <>
            {!isMobile && (
                <Button onClick={() => setOpenAssetAddForm(true)}>
                  Add Asset
                </Button>)
            }
            {isMobile && (
              <div id="asset-control-buttons"
                style={{
                  position: "fixed",
                  bottom: "75px",
                  right: "20px",
                  display: "flex",
                  gap: "20px",
                  zIndex: 0,
                  flexDirection: "column",
                }}
              >
                <Button
                  style={{
                    background: "transparent",
                    border: "none",
                    display: openAssetAddForm ? "none" : "block"
                  }}
                  onClick={() => {
                      updateCurrentAsset("addLocationResult", true);
                      openReactNativeCamera();
                  }}
                >
                  <CameraOutlined
                    style={{ color: "black", fontSize: "xxx-large" }}
                  />
                </Button>
                <Button style={{
                    background: "transparent",
                    border: "none",
                    display: openAssetAddForm ? "none" : "block",
                }} onClick={() => {
                    updateCurrentAsset("addLocationResult", false);
                    setOpenAssetAddForm(true);
                }}>
                  <CgAdd style={{ color: "black", fontSize: "xxx-large" }}/>
                </Button>

                {/*<Button*/}
                {/*  style={{*/}
                {/*    background: "transparent",*/}
                {/*    border: "none",*/}
                {/*  }}*/}
                {/*  onClick={() => openPhotoGallery()}*/}
                {/*>*/}
                {/*  <UploadOutlined*/}
                {/*    style={{ color: "#30bbd0", fontSize: "xxx-large" }}*/}
                {/*  />*/}
                {/*</Button>*/}
              </div>
            )}
          </>
        )) ||
        (tabKey == "access" && (
          <Button
            style={
              isMobile
                ? {
                  position: "fixed",
                  bottom: "75px",
                  right: "20px",
                  width: "fit-content",
                  background: "transparent",
                  border: "none",
                  zIndex: 9999,
                }
                : {}
            }
            onClick={() => setOpenAccessInvite(true)}
          >
            {isMobile ? (
              <UserAddOutlined
                style={{ color: "#30bbd0", fontSize: "xxx-large" }}
              />
            ) : (
              <>Add access</>
            )}
          </Button>
        ))
      }
      onTabClick={(key, e) => {
        navigate(`/projects/${projectId}/${key}`);
      }}
      items={[
        {
          key: "map",
          label: isMobile ? "" : "Map",
          icon: (
            <FaRegMap
              size={16}
              style={{
                verticalAlign: "middle",
                marginTop: "-2px",
              }}
            />
          ),
          children: <ProjectMap drawingsSelected={drawingsSelected} />,
        },
        {
          key: "drawings",
          label: isMobile ? "" : "Layers",
          icon: (
            <FaDrawPolygon
              size={16}
              style={{
                verticalAlign: "middle",
                marginTop: "-2px",
              }}
            />
          ),
          children: (
            <div>
              <Drawings
                drawingsSelected={drawingsSelected}
                setDrawingsSelected={setDrawingsSelected}
              />
              <AddDrawingsForm
                projectId={projectId}
                form={formDrawingsAdd}
                open={openDrawingsAddForm}
                setOpen={setOpenDrawingsAddForm}
              />
            </div>
          ),
        },
        {
          key: "assets",
          label: isMobile ? "" : "Assets",
          icon: (
            <CameraFilled
              size={16}
              style={{
                verticalAlign: "middle",
                marginTop: "-2px",
              }}
            />
          ),
          children: (
            <div>
              <Assets
                assetsSelected={assetsSelected}
                setAssetsSelected={setAssetsSelected}
                currentAsset = {currentAsset}
                updateCurrentAsset={updateCurrentAsset}
                assets={assets}
              />
              <AddAssetForm
                projectId={projectId}
                form={formAssetAdd}
                open={openAssetAddForm}
                setOpen={setOpenAssetAddForm}
                currentAsset={currentAsset}
                updateCurrentAsset={updateCurrentAsset}
                assets={assets}
              />
            </div>
          ),
        },
        {
          key: "surveys",
          label: isMobile ? "" : "Points",
          icon: (
            <FaMapMarkerAlt
              size={16}
              style={{
                verticalAlign: "middle",
                marginTop: "-2px",
              }}
            />
          ),
          children: <Surveys setSurveysSelected={setSurveysSelected} />,
        },
        {
          key: "vectors",
          label: isMobile ? "" : "Surveys",
          icon: <NodeIndexOutlined />,
          children: <Vectors setVectorsSelected={setVectorsSelected} />,
        },
        {
          key: "access",
          label: isMobile ? "" : "Access",
          icon: <UsergroupAddOutlined />,
          children: (
            <div>
              <Access />
              <AccessInvite
                projectId={projectId}
                form={formAccessInvite}
                open={openAccessInvite}
                setOpen={setOpenAccessInvite}
              />
            </div>
          ),
        },
        {
          key: "about",
          label: isMobile ? "" : "About",
          icon: <InfoCircleOutlined />,
          disabled: true,
        },
      ]}
    />
  );
}
