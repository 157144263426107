import { Layout, Table, Button, List, Checkbox, Skeleton } from "antd";
import { useGetVectorsQuery } from "../features/api/apiSlice";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import useIsMobile from "../utils/mediaQuery";
import { useState, useEffect } from "react";

export default function Vectors(props) {
  const { projectId } = useParams();
  const { setVectorsSelected } = props;

  const { data, isLoading, isSuccess, isError, error } =
    useGetVectorsQuery(projectId);

  const isMobile = useIsMobile(); // Detect mobile devices
  const [selectedKeys, setSelectedKeys] = useState([]);

  const handleSelectionChange = (checked, vectorId) => {
    setSelectedKeys(
      (prevSelected) =>
        checked
          ? [...prevSelected, vectorId] // Add vector ID to selection
          : prevSelected.filter((id) => id !== vectorId) // Remove vector ID from selection
    );
  };

  useEffect(() => {
    setVectorsSelected(
      selectedKeys.map((key) => data.find((s) => s.drawing_id === key))
    );
  }, [selectedKeys, data]);

  return (
    <Layout>
      {/* Desktop View: Table */}
      {!isMobile && (
        <Table
          style={{ width: "100%" }}
          loading={isLoading}
          rowSelection={{
            type: "checkbox",
            onChange: (keys) => setSelectedKeys(keys),
          }}
          columns={[
            {
              title: "Color",
              dataIndex: "color",
              key: "color",
              render: (color) => (
                <div
                  style={{ width: 10, height: 10, backgroundColor: color }}
                />
              ),
            },
            {
              title: "Survey",
              key: "survey_name",
              render: (name, record) =>
                `${dayjs(record.created_at).format("DD MM YYYY")} ${
                  record.name
                }` || "<UNSORTED>",
            },
            {
              title: "Drawing",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "Total length",
              dataIndex: "total_length",
              key: "total_length",
              align: "right",
              render: (l) => `${Math.round(l)} m`,
            },
            {
              title: "Latest Author",
              render: (_, record) => `${record.latestBy.email}`,
            },
            {
              title: "Latest Date",
              dataIndex: "latest_at",
              key: "latestDate",
              render: (latest_at) => dayjs(latest_at).format("DD MMM YYYY"),
              filters: isLoading
                ? []
                : [
                    ...new Set(
                      data.map(({ latest_at }) =>
                        dayjs(latest_at).format("DD MMM YYYY")
                      )
                    ),
                  ].map((val) => ({ text: val, value: val })),
              onFilter: (value, record) =>
                value === dayjs(record.latest_at).format("DD MMM YYYY"),
            },
          ]}
          dataSource={data}
        />
      )}

      {/* Mobile View: List */}
      {isMobile && (
        <List
          style={{ margin: "75px 20px" }}
          loading={isLoading}
          itemLayout="vertical"
          pagination={{ position: "bottom", align: "center" }}
          dataSource={data || []}
          renderItem={(item) => (
            <List.Item
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              key={item.drawing_id}
            >
              <Skeleton avatar title={false} loading={isLoading} active>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  {/* Checkbox for Selection */}
                  <Checkbox
                    checked={selectedKeys.includes(item.drawing_id)}
                    onChange={(e) =>
                      handleSelectionChange(e.target.checked, item.drawing_id)
                    }
                  />
                  {/* Color and Name */}
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: item.color,
                      borderRadius: "50%",
                    }}
                  ></div>
                  <p style={{ margin: 0 }}>{item.name}</p>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <p>
                    Survey:{" "}
                    {`${dayjs(item.created_at).format("DD MM YYYY")} ${
                      item.name
                    }`}
                  </p>
                  <p>Total Length: {`${Math.round(item.total_length)} m`}</p>
                  <p>Latest Author: {item.latestBy.email}</p>
                  <p>
                    Latest Date: {dayjs(item.latest_at).format("DD MMM YYYY")}
                  </p>
                </div>
              </Skeleton>
            </List.Item>
          )}
        />
      )}
    </Layout>
  );
}
