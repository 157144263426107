// import { useNavigate } from 'react-router-dom';
import { Modal, Form, Input, Upload } from "antd";
import { usePostProjectDrawingsMutation } from "../features/api/apiSlice";
import { InboxOutlined } from "@ant-design/icons";

export default function CreateProjectForm(props) {
  const { open, setOpen, projectId } = props;
  const [postProjectDrawings, { isLoading }] = usePostProjectDrawingsMutation();
  // const navigate = useNavigate();

  const [form] = Form.useForm();

  const handleConfirm = () => {
    form
      .validateFields()
      .then(async (values) => {
        const { dragger } = values;
        const zip = Array.isArray(dragger) ? dragger[0] : {};

        form.resetFields();

        if (zip?.response?.zip) {
          const data = await postProjectDrawings({
            projectId,
            zip: zip.response.zip,
          }).unwrap();
        }

        setOpen(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFileRemove = (file) => {
    console.log(file.response);
  };

  return (
    <Modal
      open={open}
      title="Add drawings"
      okText="Confirm"
      onCancel={() => {
        form.resetFields();
        setOpen(false);
      }}
      onOk={handleConfirm}
      okButtonProps={{
        loading: isLoading,
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Drawings">
          <Form.Item
            name="dragger"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            noStyle
          >
            <Upload.Dragger
              maxCount={1}
              onRemove={onFileRemove}
              accept=".dwg, .zip"
              name="file"
              action={`${process.env.REACT_APP_API_URI}/uploads`}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag zip archive to this area to upload
              </p>
              <p className="ant-upload-hint">
                Archive must contain .shp, .shx, and .dbf files for every layer.
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
}
