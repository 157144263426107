import {
  Layout,
  Table,
  Form,
  Col,
  Row,
  Button,
  Space,
  Checkbox,
  Modal,
  Flex,
  Card,
  List,
  Skeleton,
} from "antd";
import { useGetProjectDrawingsQuery } from "../features/api/apiSlice";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import EditDrawingForm from "./DrawingEdit";

import { useDeleteDrawingMutation } from "../features/api/apiSlice";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import useIsMobile from "../utils/mediaQuery";
import Meta from "antd/es/card/Meta";

const { confirm } = Modal;

export default function Drawings(props) {
  const { projectId } = useParams();

  const { drawingsSelected, setDrawingsSelected } = props;

  const [deleteDrawing] = useDeleteDrawingMutation();

  const [openDrawingEditForm, setOpenDrawingEditForm] = useState(false);

  const [formDrawingEdit] = Form.useForm();

  const isMobile = useIsMobile();

  const {
    data: project,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetProjectDrawingsQuery(projectId);

  const handleDrawingEdit = (drawing) => {
    formDrawingEdit.setFieldsValue(drawing);
    setOpenDrawingEditForm(true);
  };

  const showDeleteConfirm = (drawingId) => {
    confirm({
      title: "Are you sure delete drawing?",
      icon: <ExclamationCircleFilled />,
      content: "You cannot undo this action",
      okText: "Confirm",
      okType: "danger",
      cancelText: "No",
      onOk: async (drawingId) => {
        try {
          await deleteDrawing({ drawingId, projectId }).unwrap();
        } catch (e) {
          console.error(e);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    setDrawingsSelected(project?.drawings.map(({ key }) => key));
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && project?.drawings) {
      setDrawingsSelected([]);
    }
  }, [isLoading, project?.drawings]);

  return (
    <Layout>
      {!isMobile && (
        <Table
          style={{ width: "100%" }}
          loading={isLoading}
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: drawingsSelected,
            onChange: (keys, rows) => {
              setDrawingsSelected(keys);
            },
          }}
          columns={[
            {
              title: "Color",
              dataIndex: "color",
              key: "color",
              render: (color) => (
                <div
                  style={{ width: 10, height: 10, backgroundColor: color }}
                />
              ),
            },
            {
              title: "Name",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "Type",
              dataIndex: "type",
              key: "type",
            },
            {
              title: "Created By",
              dataIndex: "created_by",
              key: "created_by",
              render: (created_by, record) => record.email,
            },
            {
              title: "Created At",
              dataIndex: "created_at",
              key: "created_at",
              render: (created_at) =>
                dayjs(created_at).format("MMM DD YYYY @ HH:mm"),
            },
            {
              title: "Action",
              key: "action",
              render: (_, record) => (
                <Space size="middle">
                  <a onClick={() => handleDrawingEdit(record)}>Edit</a>
                  <Button onClick={() => showDeleteConfirm(record.id)} danger>
                    Delete
                  </Button>
                </Space>
              ),
            },
          ]}
          dataSource={project?.drawings}
        />
      )}
      {isMobile && (
        <List
          style={{ margin: "75px 25px" }}
          loading={isLoading}
          itemLayout="vertical"
          pagination={{ position: "bottom", align: "center" }}
          dataSource={project?.drawings || []} // Use empty array as fallback
          renderItem={(item) => (
            <List.Item
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              key={item.id} // Use a unique key for React
              actions={[
                <a onClick={() => handleDrawingEdit(item)} key="list-edit">
                  Edit
                </a>,
                <a onClick={() => showDeleteConfirm(item.id)} key="list-delete">
                  Delete
                </a>,
              ]}
            >
              <Skeleton avatar title={false} loading={isLoading} active>
                <List.Item.Meta
                  style={{ width: "100%" }}
                  title={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {/* Checkbox for Selection */}
                      <Checkbox
                        checked={drawingsSelected?.includes(item.id)}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setDrawingsSelected(
                            (prevSelected) =>
                              isChecked
                                ? [...prevSelected, item.id] // Add to selection
                                : prevSelected.filter((id) => id !== item.id) // Remove from selection
                          );
                        }}
                      />
                      {/* Color and Name */}
                      <div
                        style={{
                          width: 30,
                          height: 30,
                          backgroundColor: item.color,
                          borderRadius: "50%",
                        }}
                      ></div>
                      <p style={{ margin: 0 }}>{item.name}</p>
                    </div>
                  }
                  description={
                    <>
                      <p>Type: {item.type}</p>
                      <p>
                        Created At:{" "}
                        {dayjs(item.created_at).format("MMM DD YYYY @ HH:mm")}
                      </p>
                      <p>Created By: {item.email}</p>
                    </>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      )}

      <EditDrawingForm
        projectId={projectId}
        form={formDrawingEdit}
        open={openDrawingEditForm}
        setOpen={setOpenDrawingEditForm}
      />
    </Layout>
  );
}
