import {
  Layout,
  Table,
  Col,
  Row,
  Button,
  Space,
  Modal,
  List,
  Skeleton,
  Image,
} from "antd";
import { useGetProjectsQuery } from "../features/api/apiSlice";
import { useState } from "react";
import CreateProjectForm from "./ProjectCreate";
import { useDeleteProjectMutation } from "../features/api/apiSlice";
import { ExclamationCircleFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import useIsMobile from "../utils/mediaQuery";

const { Header, Content } = Layout;

const { confirm } = Modal;

export default function Projects() {
  const [openCreateProjectForm, setOpenCreateProjectForm] = useState(false);

  const [deleteProject] = useDeleteProjectMutation();

  const isMobile = useIsMobile();

  const { data: projects, isLoading } = useGetProjectsQuery();

  const showDeleteConfirm = (projectId) => {
    confirm({
      title: "Are you sure delete project?",
      icon: <ExclamationCircleFilled />,
      content: "You cannot undo this action",
      okText: "Confirm",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const response = await deleteProject(projectId).unwrap();

          console.log(response);
        } catch (e) {
          console.error(e);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <Layout style={{ minHeight: "100vh", marginLeft: isMobile ? 0 : 200 }}>
      <Header style={{ display: "inline-table", padding: "0 20px" }}>
        <Row
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Col>
            <a
              target="_blank"
              href="https://inframent.com"
              style={{
                display: "flex",
                alignItems: "center",
                color: "inherit",
              }}
            >
              <Image preview={false} width={40} src="../../InframentIcon.jpg" />
              <h1>Inframent</h1>
            </a>
          </Col>
          {/* <Col span={12}><Checkbox>Own projects</Checkbox></Col> */}
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            // span={12}
          >
            <Button
              type="primary"
              onClick={() => setOpenCreateProjectForm(true)}
            >
              New Project
            </Button>
          </Col>
        </Row>
      </Header>
      <Content style={{ backgroundColor: "white" }}>
        {!isMobile && (
          <Table
            loading={isLoading}
            columns={[
              {
                title: "Title",
                dataIndex: "title",
                key: "title",
                render: (title, record) => (
                  <a href={`/projects/${record.id}/map`}>{title}</a>
                ),
              },
              {
                title: "Managed By",
                dataIndex: "managed_by",
                key: "managed_by",
                render: (managed_by, record) => record.email,
              },
              {
                title: "Description",
                dataIndex: "description",
                key: "description",
              },
              {
                title: "Created At",
                dataIndex: "created_at",
                key: "created_at",
                render: (created_at) =>
                  dayjs(created_at).format("MMM DD YYYY @ HH:mm"),
              },
              {
                title: "Action",
                key: "action",
                render: (_, record) => (
                  <Space size="middle">
                    <Button onClick={() => showDeleteConfirm(record.id)} danger>
                      Delete
                    </Button>
                  </Space>
                ),
              },
            ]}
            dataSource={projects}
          />
        )}
        {isMobile && (
          <List
            itemLayout="vertical"
            size="large"
            loading={isLoading}
            pagination={{ position: "bottom", align: "center" }}
            dataSource={projects || []}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                actions={[
                  <Button
                    type="link"
                    onClick={() => showDeleteConfirm(item.id)}
                    danger
                    key="delete"
                  >
                    Delete
                  </Button>,
                ]}
              >
                <Skeleton loading={isLoading} active>
                  <List.Item.Meta
                    title={
                      <a href={`/projects/${item.id}/map`}>{item.title}</a>
                    }
                    description={
                      <>
                        <p>Managed By: {item.managed_by?.email || "N/A"}</p>
                        <p>
                          Created At:{" "}
                          {dayjs(item.created_at).format("MMM DD YYYY @ HH:mm")}
                        </p>
                      </>
                    }
                  />
                  <p>{item.description}</p>
                </Skeleton>
              </List.Item>
            )}
          />
        )}
      </Content>
      <CreateProjectForm
        open={openCreateProjectForm}
        setOpen={setOpenCreateProjectForm}
      />
    </Layout>
  );
}
