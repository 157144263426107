import { Flex, Button, Card, Image, Divider } from "antd";
import { useGetProjectMapQuery } from "../features/api/apiSlice";
import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import * as geolib from "geolib";

import * as maptilersdk from "@maptiler/sdk";
import "@maptiler/sdk/dist/maptiler-sdk.css";
import dayjs from "dayjs";
import useIsMobile from "../utils/mediaQuery";

export default function ProjectMap(props) {
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const [surveyPreview, setSurveyPreview] = useState();

  const [showPointCard, setShowPointCard] = useState();

  const { projectId } = useParams();

  const { drawingsSelected } = props;
  const [surveysMarkers, setSurveysMarkers] = useState([]);
  const [disrtibutionPointsMarkers, setDistributionPointsMarkers] = useState(
    []
  );
  const [mapLoaded, setMapLoaded] = useState(false);

  // https://docs.maptiler.com/sdk-js/api/layers/#line
  // https://docs.maptiler.com/gl-style-specification/layers/#line .. text-field property

  const { data: project } = useGetProjectMapQuery(projectId);

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [zoom, setZoom] = useState(14);
  maptilersdk.config.apiKey = "Ha0wpPicSoh3DDh4limA";

  // map.current?.on('load', console.log('load'));

  const isMobile = useIsMobile();

  useEffect(() => {
    console.log("Used1");
    if (!project || !map.current) return;

    map.current.on("drag", (e) => {
      const { lng, lat } = map.current.getCenter();
      const zoom = map.current.getZoom();
      window.localStorage.setItem(
        `ProjectMap:${project.id}`,
        `${zoom}:${lng}:${lat}`
      );
    });

    const localStorageProjectMap = window.localStorage.getItem(
      `ProjectMap:${project.id}`
    );

    if (urlSearch && urlSearch.get("lng") && urlSearch.get("lat")) {
      map.current.setCenter([+urlSearch.get("lng"), +urlSearch.get("lat")]);
      map.current.setZoom(20);
    } else if (localStorageProjectMap) {
      const [zoom, lng, lat] = localStorageProjectMap.split(":");
      map.current.setCenter([lng, lat]);
      map.current.setZoom(zoom);
    } else {
      map.current.centerOnIpPoint(zoom);
    }
  }, [project, map.current, location]);

  useEffect(() => {
    if (map.current) return; // stops map from intializing more than once

    map.current = new maptilersdk.Map({
      container: mapContainer.current,
      style: "nl-cartiqo-topo",
    });

    map.current.on("load", () => {
      setMapLoaded(true);
    });
  }, [zoom]);

  useEffect(() => {
    if (!project || !mapLoaded) return;

    // Load Layer and DPArea
    for (const drawing of project.drawings) {
      if (!["Layer", "DPArea"].includes(drawing.type)) continue;

      const source = map.current.getSource(drawing.name);

      if (!source) {
        map.current.addSource(drawing.name, {
          type: "geojson",
          data: drawing.geoJSON,
        });
      }
    }

    // DPArea
    for (const drawing of project.drawings) {
      if ("DPArea" != drawing.type) continue;

      const layer = map.current.getLayer(drawing.name);
      if (layer) {
        if (!drawingsSelected || !drawingsSelected.includes(drawing.key)) {
          map.current.removeLayer(drawing.name);
        }
        continue;
      }

      map.current.addLayer({
        id: drawing.name,
        type: "line",
        source: drawing.name,
        paint: {
          "line-color": drawing.color,
          "line-width": 20,
        },
      });
    }

    // Layers
    for (const drawing of project.drawings) {
      if ("Layer" != drawing.type) continue;

      const layer = map.current.getLayer(drawing.name);
      if (layer) {
        if (!drawingsSelected?.includes(drawing.key)) {
          map.current.removeLayer(drawing.name);
        }
        continue;
      }

      map.current.addLayer({
        id: drawing.name,
        type: "line",
        source: drawing.name,
        paint: {
          "line-color": drawing.color,
        },
      });
    }

    // DP
    disrtibutionPointsMarkers.map((dp) => dp.remove());

    for (const drawing of project.drawings) {
      if ("DP" != drawing.type) continue;

      for (const dp of drawing.geoJSON.features) {
        const marker = new maptilersdk.Marker({
          color: drawing.color,
        })
          .setLngLat(dp.geometry.coordinates)
          .addTo(map.current);

        marker._element.onmouseenter = (e) =>
          (e.target.style.cursor = "pointer");
        marker._element.onclick = () => console.log("click");

        const div = document.createElement("div");
        div.style.position = "absolute";
        div.style["white-space"] = "nowrap";
        div.appendChild(document.createTextNode(dp.properties.Label));

        marker._element.appendChild(div);

        disrtibutionPointsMarkers.push(marker);
      }

      setDistributionPointsMarkers(disrtibutionPointsMarkers);
    }

    // Surveys
    surveysMarkers.map((dp) => dp.remove());

    for (const survey of project.surveys) {
      const marker = new maptilersdk.Marker({
        color: survey.drawing_color,
        scale: [surveyPreview?.id, +urlSearch.get("pointId")].includes(
          survey.id
        )
          ? 1.5
          : 1,
      })
        .setLngLat([survey.longitude, survey.latitude])
        .addTo(map.current);

      marker._element.onmouseenter = (e) => (e.target.style.cursor = "pointer");
      marker._element.onclick = () => setSurveyPreview(survey);

      const div = document.createElement("div");
      div.style.position = "absolute";
      div.style["white-space"] = "nowrap";

      marker._element.appendChild(div);

      surveysMarkers.push(marker);
    }

    setSurveysMarkers(surveysMarkers);

    // Links
    const source = map.current.getSource("links");

    if (!source) {
      const vectorsGeoJSON = {
        type: "FeatureCollection",
        features: project.links
          .map((l) => {
            const survey = project.surveys.find((s) => s.id == l.survey_id);
            const surveyTo = project.surveys.find(
              (s) => s.id == l.survey_to_id
            );
            const drawing = project.drawings.find(
              (d) => d.id == survey.drawing_id
            );
            return { ...l, survey, surveyTo, drawing };
          })
          .filter((l) => l.survey && l.surveyTo)
          .map((l) => ({
            type: "Feature",
            properties: {
              title: `${geolib.getDistance(
                [l.survey.longitude, l.survey.latitude],
                [l.surveyTo.longitude, l.surveyTo.latitude]
              )} m`,
              color: l?.drawing?.color || "grey",
            },
            geometry: {
              type: "LineString",
              coordinates: [
                [l.survey.longitude, l.survey.latitude],
                [l.surveyTo.longitude, l.surveyTo.latitude],
              ],
            },
          })),
      };

      map.current.addSource("links", {
        type: "geojson",
        data: vectorsGeoJSON,
      });
    }

    const layer = map.current.getLayer("links");
    if (layer) {
      map.current.removeLayer("links");
    }

    map.current.addLayer({
      id: "links",
      type: "line",
      source: "links",
      paint: {
        "line-color": ["get", "color"],
        "line-width": 20,
        "line-dasharray": [3, 2],
      },
    });

    const layerLinkSymbols = map.current.getLayer("link-symbols");
    if (layerLinkSymbols) {
      map.current.removeLayer("link-symbols");
    }

    map.current.addLayer({
      id: "link-symbols",
      type: "symbol",
      source: "links",
      layout: {
        "symbol-placement": "line",
        "text-font": ["Open Sans Regular"],
        "text-field": "{title}",
        "text-size": 16,
        "text-offset": [0, 1],
      },
    });

    console.log(map.current);

    const allFeatures = map.current.queryRenderedFeatures();

    console.log(allFeatures);
  }, [mapLoaded, project, drawingsSelected, location, surveyPreview]);

  return (
    <div>
      {surveyPreview && (
        <Card
          // hoverable
          style={{
            padding: isMobile ? "75px 0 0 0" : "",
            position: "absolute",
            top: "50%",
            left: 196,
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: 320,
            zIndex: 999,
          }}
          cover={
            <Image
              preview={{
                src: `https://ucarecdn.com/${surveyPreview.uuid}/`,
              }}
              src={`https://ucarecdn.com/${surveyPreview.uuid}/-/scale_crop/320x320/center/`}
            />
          }
          actions={[
            <Button
              onClick={() => setSurveyPreview()}
              style={{
                position: "relative",
                float: "right",
                marginRight: 12,
              }}
            >
              Close
            </Button>,
          ]}
        >
          <Card.Meta
            title={dayjs(surveyPreview.created_at).format("DD MMM YYYY HH:mm")}
            description={
              <Flex gap={"small"} vertical align="left">
                <Flex flex={1}>{surveyPreview.email}</Flex>
              </Flex>
            }
          />

          <Divider />

          <Card.Meta
            description={
              <Flex gap={"small"} vertical align="left">
                {surveyPreview.drawing_name && (
                  <Flex gap={"small"} align="center">
                    <Flex
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: surveyPreview.drawing_color,
                      }}
                    >
                      {" "}
                    </Flex>
                    <Flex flex={1}>{surveyPreview.drawing_name}</Flex>
                  </Flex>
                )}
                {!surveyPreview.drawing_name && (
                  <i style={{ color: "grey" }}>No drawing</i>
                )}
                <Flex flex={1}>
                  {surveyPreview.depth ? (
                    `Depth ${surveyPreview.depth} m`
                  ) : (
                    <i>No depth</i>
                  )}
                </Flex>
                <Flex flex={1}>{surveyPreview.note || <i>No note</i>}</Flex>
              </Flex>
            }
          />
        </Card>
      )}
      <Flex ref={mapContainer} style={{ top: isMobile ? "70px" : "0", height: "calc(100vh - 128px)" }} />
    </div>
  );
}
