import React, {useEffect, useRef, useState} from "react";
import { Layout, Table, Flex, Card, Image, Space, List, Skeleton, message } from "antd";
import { useMediaQuery } from "react-responsive";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import {
    useGetSurveysQuery, usePatchSurveyLocationMutation, usePatchSurveyPhotoMutation,
} from "../features/api/apiSlice";
import { Dropdown } from 'primereact/dropdown';
import dayjs from "dayjs";
import { Map, Marker, MapStyle, config } from '@maptiler/sdk';
const maptilersdk = { Map, Marker, MapStyle, config };


export default function Assets(props) {
    const [dropdownVisible, setDropdownVisible]= useState(false);
    const { projectId } = useParams();
    const [mapChoiceVisible, setMapChoiceVisible] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const navigate = useNavigate();
    const [test, setTest] = useState(null)
    const [refreshKey, setRefreshKey] = useState(0);
    const [patchSurveyLocation] = usePatchSurveyLocationMutation();
    const [patchSurveyPhoto] = usePatchSurveyPhotoMutation();

    const forceRefresh = () => {
        setRefreshKey((prev) => prev + 1); // Increment the counter to trigger re-render
    };


    const { assetsSelected, setAssetsSelected, currentAsset, updateCurrentAsset } = props;


    useEffect(() => {
        window.updateCurrentAsset = updateCurrentAsset;

        // Cleanup to avoid leaking the global
        return () => {
            delete window.updateCurrentAsset;
        };
    }, []);

    const {
        data: assets = [],
        isLoading,
        isSuccess,
        isError,
        error,
        refetch
    } = useGetSurveysQuery(projectId);

    const refetchAssets = () => {
        refetch();
    }

    updateCurrentAsset("refetchAll", refetch);

    const isMobile = useMediaQuery({ maxWidth: 768 });

        useEffect(() => {
        setAssetsSelected(assets.map(({ key }) => key));
    }, [isLoading]);

    const addPhoto = (assetId) => {
        console.log(`Add photo for asset id: ${assetId}`);
    }

    const addLocation = (assetId) => {
        console.log(`Add location for asset id: ${assetId}`);
    }

    const hideChoiceMap = () => {
        setMapChoiceVisible(false);
    }

    const updateCurrentAssetLngLat = () => {
        const lngLatObj = window.marker.getLngLat();

        updateCurrentAsset("lngLat", [lngLatObj.lng, lngLatObj.lat]);
    }

    const showDropdown = () => {
        setDropdownVisible(true);
    }

    // Initialize the map
    window.displayChoiceMap = ([lat, lng]) => {
        const map = new maptilersdk.Map({
            container: "map", // ID of the div where the map is rendered
            style: maptilersdk.MapStyle.HYBRID,
            center: [4.897070, 52.377956],
            zoom: 18,
            apiKey: "fyS1MadsVWubFDBlqgv6", // Replace with your MapTiler API key
        });

        const marker = new maptilersdk.Marker({
            draggable: true,
        })
            .setLngLat([4.897070, 52.377956]) // Amsterdam Coordinates
            .addTo(map);

        const coordinates = document.getElementById("coordinates");

        window.marker = marker;
        window.map = map;


        map.setCenter([lng, lat])
        marker.setLngLat([lng, lat])
        setMapChoiceVisible(true);

        marker.on("dragend", () => {
            const lngLat = marker.getLngLat();
            coordinates.style.display = "block";
            coordinates.innerHTML = `
            Longitude: ${lngLat.lng}<br />
            Latitude: ${lngLat.lat}
          `;
        });
    }


    const handleUpdateAssetLocation = async () => {
        updateCurrentAsset("lngLat", window.marker.getLngLat());
        const reqLocationBody = { projectId: projectId, surveyId: selectedAsset.id, latitude: currentAsset.lngLat.lat, longitude: currentAsset.lngLat.lng };
        const reqUuidBody = { projectId: projectId, surveyId: selectedAsset.id, uuid: currentAsset?.uuid?.file};

        const foundAsset = assets.find(asset => asset.id === selectedAsset.id);


        let locationUpdateError = false;
        await patchSurveyPhoto(reqUuidBody).catch((err) => photoUpdateError = true);
        await patchSurveyLocation(reqLocationBody).catch((err) => locationUpdateError = true);


        let photoUpdateError = false;


        refetchAssets()
    }

    return (
        <Layout refreshKey={refreshKey}>
            {/* Map Container */}
            <div id="map-overlay" style={{
                position: "absolute",
                width: "100vw",
                height: "120vh",
                backgroundColor: "rgba(100,100,100,0.75)",
                zIndex: "10000",
                left: isMobile ? "0" : "-200px",
                top: isMobile ? "0" : "-200px",
                display: mapChoiceVisible || dropdownVisible ? "block" : "none"
            }}></div>
            <div id="dropdown-container" style={{
                position: "absolute",
                display: dropdownVisible ? "flex" : "none",
                width: "100vw",
                height: "fit-content",
                zIndex: "10002",
                alignItems: "center",
                justifyContent: "center",
                top: "40vh"
            }}>
                <Dropdown optionstyle={{
                    position: "absolute",

                }} value={selectedAsset} onChange={(e) => {console.log("E value is: " + e.value);setSelectedAsset(e.value)}}
                          options={assets || []} optionLabel="note"
                          placeholder="Assign to Asset"
                          filter className="w-full md:w-14rem"/>
                <div id="dropdown-container-control-buttons" style={{
                    position: "absolute",
                    width: "100vw",
                    bottom: "-50vh",
                    left: "0px",
                    display: "flex",
                    alignSelf: "center",
                    gap: "30px",
                    zIndex: 10002,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    {/*<p>{JSON.stringify(selectedAsset)}</p>*/}
                    <Button onClick={() => setDropdownVisible(false)} icon="pi pi-times" rounded severity="danger" aria-label="Cancel"/>
                    <Button
                        onClick={() => {setDropdownVisible(false); handleUpdateAssetLocation();}}
                        icon="pi pi-check" rounded aria-label="Filter"/>
                </div>
            </div>
            <div id="map-choice-container" style={{display: mapChoiceVisible ? "block" : "none"}}>

                <div style={{
                    position: "absolute",
                    width: "100vw",
                    height: "500px",
                    zIndex: "10001",
                    left: isMobile ? "0" : "-200px",
                    margin: "20px 0"
                }}></div>
                <div
                    id="map"
                    style={{
                        position: "absolute",
                        top: isMobile ? "15vh" : "5vh",
                        left: isMobile ? "0" : "-200px",
                        width: "100vw",
                        height: "70vh",
                        zIndex: "10001"
                    }}
                ></div>
                <pre
                    id="coordinates"
                    style={{
                        background: "rgba(0, 0, 0, 0.5)",
                        color: "#fff",
                        position: "absolute",
                        bottom: "10px",
                        left: "10px",
                        padding: "5px 10px",
                        margin: 0,
                        fontSize: "12px",
                        lineHeight: "18px",
                        borderRadius: "3px",
                        // display: "none",
                    }}
                ></pre>
                <div id="map-container-control-buttons" style={{
                    position: "fixed",
                    width: "100vw",
                    bottom: "5vh",
                    left: "0px",
                    display: "flex",
                    alignSelf: "center",
                    gap: "30px",
                    zIndex: 10002,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Button onClick={hideChoiceMap} icon="pi pi-times" rounded severity="danger" aria-label="Cancel" />
                    <Button onClick={() => {if (currentAsset.addLocationResult) {hideChoiceMap();showDropdown();} else {updateCurrentAssetLngLat(); hideChoiceMap();}}}
                        icon="pi pi-check" rounded aria-label="Filter" />
                </div>


            </div>
            {!isMobile && (
                <Table
                    style={{ width: "100%" }}
                    loading={isLoading}
                    rowSelection={{
                        type: "checkbox",
                        selectedRowKeys: assetsSelected.map((asset) => asset.id),
                        onChange: (keys, rows) => {
                            setAssetsSelected(keys.map((k) => assets.find((a) => a.id === k)));
                        },
                    }}
                    columns={[
                        {
                            title: "Preview",
                            dataIndex: "uuid",
                            key: "uuid",
                            render: (uuid) => (
                                <Image
                                    src={`https://ucarecdn.com/${uuid}/-/scale_crop/120x120/center/`}
                                    preview={{ src: `https://ucarecdn.com/${uuid}/` }}
                                />
                            ),
                        },
                        {
                            title: "Location",
                            key: "location",
                            render: (asset) => (
                                <Image
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        navigate(
                                            `/projects/${projectId}/map?&assetId=${asset.id}&lng=${asset.longitude}&lat=${asset.latitude}`
                                        )
                                    }
                                    preview={false}
                                    src={`https://api.maptiler.com/maps/nl-cartiqo-topo/static/${asset.longitude},${asset.latitude},18/120x120.png?key=Ha0wpPicSoh3DDh4limA&markers=${asset.longitude},${asset.latitude}`}
                                />
                            ),
                        },
                        // {
                        //     title: "Color",
                        //     dataIndex: "color",
                        //     key: "color",
                        // },
                        {
                            title: "Name",
                            dataIndex: "note",
                            key: "name",
                            render: () => "TBD"
                        },
                        {
                            title: "Installation Date",
                            //   dataIndex: "created_at",
                            key: "installed_at",
                            render: (asset) =>
                                dayjs(asset.created_at).format("MMM DD YYYY @ HH:mm"),
                        },
                        {
                            title: "Next Maintenance Date",
                            dataIndex: "next_maintenance",
                            key: "next_maintenance",
                            render: (next_maintenance) =>
                                dayjs(next_maintenance).format("MMM DD YYYY @ HH:mm"),
                        },
                        {
                            title: "Recorded by",
                            dataIndex: "created_by",
                            key: "created_by",
                            render: (created_by, record) => record.email,
                        },
                        //   {
                        //       title: "Actions",
                        //       key: "action",
                        //       render: (_, record) => (
                        //           <Space size="middle" direction="vertical">
                        //               <Button
                        //                   type="link"
                        //                   onClick={() => addPhoto(record.id)}
                        //               >
                        //                   Add Photo
                        //               </Button>
                        //               <Button
                        //                   type="link"
                        //                   onClick={() => addLocation(record.id)}
                        //               >
                        //                   Add Location
                        //               </Button>
                        //           </Space>
                        //       ),
                        //   },
                    ]}
                    dataSource={assets.map((asset) => ({
                        ...asset,
                        key: asset.id || asset.name, // Ensure unique keys
                    }))}
                />
            )}

            {/* Mobile View */}
            {isMobile && (
                <List
                    itemLayout="vertical"
                    style={{ margin: "75px 20px" }}
                    size="large"
                    loading={isLoading}
                    pagination={{ position: "bottom", align: "center" }}
                    dataSource={assets || []}
                    renderItem={(asset) => (
                        <List.Item
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column-reverse",
                                justifyContent: "center",
                                alignItems: "center",
                                listStyle: "none"
                            }}
                            key={asset.id || asset.name}
                            actions={[
                                asset.longitude && asset.latitude ? (
                                    <a
                                        onClick={() =>
                                            navigate(
                                                `/projects/${projectId}/map?&assetId=${asset.id}&lng=${asset.longitude}&lat=${asset.latitude}`
                                            )
                                        }
                                    >
                                        Point location
                                    </a>
                                ) : (
                                    <a
                                        onClick={() => addLocation(asset.id)}
                                    >
                                        Add Location
                                    </a>
                                ),
                            ]}
                            extra={
                                <Image
                                    width={120}
                                    src={`https://ucarecdn.com/${asset.uuid}/-/scale_crop/120x120/center/`}
                                    preview={{ src: `https://ucarecdn.com/${asset.uuid}/` }}
                                />
                            }
                        >
                            <Skeleton style={{ width: "100%" }} loading={isLoading} active>
                                <List.Item.Meta
                                    style={{ width: "100%" }}
                                    title={
                                        <div>
                                            <span>{asset.name || "No Name"}</span>
                                            <br />
                                            <small>
                                                Installed:{" "}
                                                {dayjs(asset.installed_at).format(
                                                    "MMM DD YYYY @ HH:mm"
                                                )}
                                            </small>
                                        </div>
                                    }
                                    description={
                                        <>
                                            Next Maintenance:{" "}
                                            {dayjs(asset.next_maintenance).format(
                                                "MMM DD YYYY @ HH:mm"
                                            )}
                                            <br />
                                            Recorded by: {asset.email || "Unknown"}
                                        </>
                                    }
                                />
                            </Skeleton>
                        </List.Item>
                    )}
                />
            )}

        </Layout>
    );
}