import { Navigate } from "react-router-dom";
import { auth } from "../firebase";
import { useState, useEffect } from "react";

export default function AuthRoute({ children }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        // Listen to Firebase auth state changes
        auth.onAuthStateChanged(async (firebaseUser) => {
          if (firebaseUser) {
            try {
              // Firebase user exists, set as user
              const token = await firebaseUser.getIdToken(true); // Optionally refresh token
              setUser(firebaseUser);
            } catch (error) {
              console.error("Error refreshing Firebase token:", error);
              setUser(null);
            }
          } else {
            // No Firebase user, check JWT token
            const jwtToken = localStorage.getItem("authToken");
            if (jwtToken) {
              try {
                // Optionally validate JWT here (e.g., use jwt-decode or custom validation)
                console.log("JWT token validated:", jwtToken);
                setUser({ jwtToken }); // Set user as an object with JWT token
              } catch (error) {
                console.error("Invalid or expired JWT token:", error);
                localStorage.removeItem("authToken"); // Clear invalid token
                setUser(null);
              }
            } else {
              setUser(null); // No Firebase user or JWT token
            }
          }
          setLoading(false); // Authentication check complete
        });
      } catch (error) {
        console.error("Error during authentication:", error);
        setUser(null);
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  return loading ? (
    <></>
  ) : (user || localStorage.getItem("authToken")) ? (
    children
  ) : (
    <Navigate to="/auth/sign-in" replace />
  );
}
