// https://redux.js.org/tutorials/essentials/part-7-rtk-query-basics

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { auth } from "../../firebase";

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URI,
    prepareHeaders: async (headers, { endpoint }) => {
      if (["register", "login"].includes(endpoint)) {
        return headers;
      }

      if (auth.currentUser) {
        const idToken = await auth.currentUser.getIdToken();
        if (idToken) headers.set("authorization", `Bearer ${idToken}`);
      }

      if (localStorage.getItem("authToken")) {
        const token = localStorage.getItem("authToken");
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: [
    "Profile",
    "Projects",
    "Project",
    "Map",
    "Drawings",
    "Access",
    "Surveys",
    "Survey",
    "Assets",
  ],
  endpoints: (builder) => ({
    // User Authentication Endpoints
    register: builder.mutation({
      query: (body) => ({
        url: "/auth/register",
        method: "POST",
        body,
      }),
    }),
    login: builder.mutation({
      query: (body) => ({
        url: "/auth/login",
        method: "POST",
        body,
      }),
    }),
    // Profile
    getProfile: builder.query({
      query: () => "/profile",
      providesTags: ["Profile"],
    }),
    postProfile: builder.mutation({
      query: (body) => ({
        url: "/profile",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Profile"],
    }),

    // Assets
    // getAssets: builder.query({
    //   query: (projectId) => `/projects/${projectId}/assets`, // Define API route
    //   providesTags: ["Assets"],
    // }),
    // getAsset: builder.query({
    //   query: (projectId, assetId) => `/projects/${projectId}/assets/${assetId}`,
    //   providesTags: ["Assets"],
    // }),
    postAsset: builder.mutation({
      query: ({ projectId, asset }) => ({
        url: `/projects/${projectId}/assets/`,
        method: "POST",
        body: asset,
      }),
      invalidatesTags: ["Assets"],
    }),
    // deleteAsset: builder.mutation({
    //   query: ({ projectId, assetId }) => ({
    //     url: `/projects/${projectId}/assets/${assetId}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["Assets"],
    // }),

    // Projects
    getProjects: builder.query({
      query: () => "/projects",
      providesTags: ["Projects"],
    }),
    getProject: builder.query({
      query: (projectId) => `/projects/${projectId}`,
      providesTags: ["Project"],
    }),
    getProjectMap: builder.query({
      query: (projectId) => `/projects/${projectId}/map`,
      providesTags: ["Project", "Map"],
    }),
    postProject: builder.mutation({
      query: (initialProject) => ({
        url: "/projects",
        method: "POST",
        body: initialProject,
      }),
      invalidatesTags: ["Projects"],
    }),
    deleteProject: builder.mutation({
      query: (projectId) => ({
        url: `/projects/${projectId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Projects"],
    }),

    // Drawings
    postProjectDrawings: builder.mutation({
      query: ({ projectId, zip }) => ({
        url: `/projects/${projectId}/drawings`,
        method: "POST",
        body: { zip },
      }),
      invalidatesTags: ["Drawings", "Map"],
    }),
    getProjectDrawings: builder.query({
      query: (projectId) => `/projects/${projectId}/drawings`,
      providesTags: ["Drawings"],
    }),
    postProjectDrawingId: builder.mutation({
      query: ({ projectId, id, type, name }) => ({
        url: `/projects/${projectId}/drawings/${id}`,
        method: "POST",
        body: {
          type,
          name,
        },
      }),
      invalidatesTags: ["Map", "Drawings"],
    }),
    deleteDrawing: builder.mutation({
      query: ({ projectId, drawingId }) => ({
        url: `/projects/${projectId}/drawings/${drawingId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Map", "Drawings"],
    }),

    // Access
    getProjectAccess: builder.query({
      query: (projectId) => `/projects/${projectId}/access`,
      providesTags: ["Access"],
    }),
    postProjectAccess: builder.mutation({
      query: ({ projectId, email, phone, role }) => ({
        url: `/projects/${projectId}/access`,
        method: "POST",
        body: { email, phone, role },
      }),
      invalidatesTags: ["Access"],
    }),
    postProjectAccessUserId: builder.mutation({
      query: ({ projectId, userId, role }) => ({
        url: `/projects/${projectId}/access/${userId}`,
        method: "POST",
        body: { role },
      }),
      invalidatesTags: ["Access"],
    }),

    // Surveys
    getSurveys: builder.query({
      query: (projectId) => `/projects/${projectId}/surveys`,
      providesTags: ["Surveys"],
    }),
    deleteSurveyId: builder.mutation({
      query: ({ projectId, surveyId }) => ({
        url: `/projects/${projectId}/surveys/${surveyId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Surveys"],
    }),
    exportSurveys: builder.mutation({
      query: ({ projectId, surveyIds }) => ({
        url: `/projects/${projectId}/export-surveys/?surveyIds=${surveyIds}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    // Survey update methods
    patchSurveyLocation: builder.mutation({
      query: ({ projectId, surveyId, latitude, longitude }) => ({
        url: `/projects/${projectId}/surveys/${surveyId}/location`,
        method: "PATCH",
        body: { latitude, longitude }
      })
    }),
    patchSurveyPhoto: builder.mutation({
      query: ({ projectId, surveyId, uuid }) => ({
        url: `/projects/${projectId}/surveys/${surveyId}/uuid`,
        method: "PATCH",
        body: { uuid }
      })
    }),

    // Vectors
    getVectors: builder.query({
      query: (projectId) => `/projects/${projectId}/vectors`,
      providesTags: ["Vectors"],
      transformResponse: (data) => {
        for (const d of data) {
          d.key = d.drawing_id;
        }
        return data;
      },
    }),
    exportVectors: builder.mutation({
      query: ({ projectId, drawingIds }) => ({
        url: `/projects/${projectId}/export-vectors/?drawingIds=${drawingIds}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const {
  useRegisterMutation,
  useLoginMutation,

  useGetProfileQuery,
  usePostProfileMutation,

  // useGetAssetsQuery,
  usePostAssetMutation,
  // useDeleteAssetMutation,

  useGetProjectsQuery,
  useGetProjectQuery,
  useGetProjectMapQuery,
  usePostProjectMutation,
  useDeleteProjectMutation,

  useGetProjectDrawingsQuery,
  usePostProjectDrawingsMutation,
  usePostProjectDrawingIdMutation,
  useDeleteDrawingMutation,

  useGetProjectAccessQuery,
  usePostProjectAccessMutation,
  usePostProjectAccessUserIdMutation,

  useGetSurveysQuery,
  useDeleteSurveyIdMutation,
  useExportSurveysMutation,
  usePatchSurveyLocationMutation,
  usePatchSurveyPhotoMutation,

  useGetVectorsQuery,
  useExportVectorsMutation,
} = apiSlice;
